@import "@/styles/variables.scss"; 









































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.import-dialog .vxe-table--tooltip-wrapper {
    z-index: 10000 !important;
}

.import-dialog {
    .sortable-ghost {
        background: #fff !important;
        // opacity: 0 !important;
    }

    .sortable-chosen {
        .icon-caiddang {
            color: #1890ff;
        }

        background: #fff !important;
        border: 1px solid #1890ff;
        color: #1890ff;
        opacity: 1 !important;
    }

    .btn-orange {
        background-color: #ff9648;
        color: #fff;
    }
}

.import-upload {
    width: 100%;
    margin-bottom: 15px;

    &-tips1 {
        // font-size: 12px;
        line-height: 1.5;
        color: #000;
        font-weight: 600;
        margin-bottom: 25px;
        // font-size: 15px;
    }

    &-tips {
        // font-size: 12px;
        line-height: 1.5;
    }

    .el-upload {
        width: 100%;

        .el-upload-dragger {
            width: 100%;
        }
    }

    &-list {
        width: 90%;
        margin: 0 auto;
        // padding-top: 10px;
    }

    &-form {
        display: flex;
        // justify-content: space-between;
        width: 100%;
        padding: 10px 0;

        &-item {
            flex: 1;

            .required-tips {
                font-size: 12px;
                margin-top: 5px;
                color: red;
                display: none;
            }
        }

        &-label {
            margin-bottom: 5px;

            .import-required {
                color: red;
                line-height: 14px;
            }
        }
    }
}

.import-success {
    text-align: center;
    padding: 50px 0;

    &-icon {
        font-size: 40px;
        color: #67c23a;
        margin-bottom: 10px;
    }

    &-tips {
        margin-top: 10px;

        .text-primary {
            color: #1890ff;
            cursor: pointer;
        }
    }
}

.column {
    margin-top: 10px;
    // border: 1px solid #d9d9d9;
    // border-radius: 6px;
    // padding: 15px;

    .column-list {
        // margin-top: 20px;
        padding: 5px 15px 15px 0;
        max-height: 300px;
        overflow-y: auto;

        &-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 5px;
            line-height: 1;
            padding: 10px;
            margin-top: 10px;
            border: 1px solid rgba(0, 0, 0, 0.05);
            background: #fff;

            .icon {
                position: relative;
                z-index: 9999;
            }
            .el-input-group__prepend {
                padding: 0 5px !important;
            }

            // &:hover {
            // 	border: 1px solid #1890ff;
            // 	color: #1890ff;
            // }
        }
    }
}

// .custom-dragging {
// 	background-color:#1890ff;
// }

.sortable-ghost {
    background: #fff !important;
    // opacity: 0 !important;
}

.sortable-chosen {
    .icon-caiddang {
        color: #1890ff;
    }

    background: #fff !important;
    border: 1px solid #1890ff;
    color: #1890ff;
    opacity: 1 !important;
}
.import-el-table {
    min-height: 100px;
}
.import-el-table .el-table th.el-table__cell {
    background-color: transparent !important;
}
